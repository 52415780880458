import { HttpTransportType, HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { refreshTokenAsync } from "@vatsim-vnas/js-libs/api/vnas";
import { FlightStripsStateDto, SessionInfoDto, StripItemDto } from "@vatsim-vnas/js-libs/models/vnas/messaging";
import { tokenHasExpired } from "@vatsim-vnas/js-libs/utils";
import { plainToInstance } from "class-transformer";
import { decodeJwt } from "jose";
import React, { ReactNode, createContext, useContext, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useFlightStripsInterface } from "src/hooks";
import {
  addOrUpdateStripItem,
  environmentSelector,
  facilityIdSelector,
  getFlightStripsFacilities,
  hideContextMenu,
  logout,
  nasTokenSelector,
  setFacility,
  setFsdIsConnected,
  setSelectedStripItem,
  setSession,
  setSessionActive,
  setSignalrIsConnected,
  signalrIsConnectedSelector,
  useAppDispatch,
  useAppSelector,
  vatsimTokenSelector,
} from "src/redux";
import { OVERRIDE_API_URL, OVERRIDE_CLIENT_HUB_URL, SIGNALR_IDLE_TIMEOUT_MS, VERSION, debugLog } from "src/utils";

const HubContext = createContext<HubConnection>(undefined!);

interface HubProviderProps {
  children: ReactNode;
}

export function HubProvider({ children }: Readonly<HubProviderProps>) {
  const environment = useAppSelector(environmentSelector)!;
  const vatsimToken = useAppSelector(vatsimTokenSelector)!;
  const nasToken = useAppSelector(nasTokenSelector)!;
  const signalrIsConnected = useAppSelector(signalrIsConnectedSelector);
  const facilityId = useAppSelector(facilityIdSelector);
  const facilityIdRef = useRef(facilityId);
  const dispatch = useAppDispatch();

  const { receiveFlightStripsState } = useFlightStripsInterface();
  const receiveFlightStripsStateRef = useRef(receiveFlightStripsState);
  const timeoutFunctionRef = useRef<NodeJS.Timeout>();
  const hubConnectionRef = useRef<HubConnection>(undefined!);

  useEffect(() => {
    facilityIdRef.current = facilityId;
  }, [facilityId]);

  async function getValidNasToken() {
    const decodedToken = decodeJwt(nasToken);
    if (tokenHasExpired(decodedToken)) {
      debugLog("Refreshing NAS token");
      const res = await refreshTokenAsync(OVERRIDE_API_URL ?? environment.apiBaseUrl, vatsimToken);
      return res.data!;
    }
    debugLog("Found NAS token");
    return nasToken;
  }

  async function joinSession(sessionInfo: SessionInfoDto) {
    debugLog("Joining vNAS session");
    await hubConnectionRef.current.invoke("JoinSession", {
      sessionId: sessionInfo.id,
      clientName: "vStrips",
      clientVersion: VERSION,
      sysUid: "",
    });
    dispatch(setSession(sessionInfo));
    dispatch(getFlightStripsFacilities());
  }

  async function initializeSignalR(startConnection: boolean) {
    const hubConnection = hubConnectionRef.current;
    try {
      if (startConnection) {
        await hubConnection.start();
      }
      dispatch(setSignalrIsConnected(true));
      const sessions: SessionInfoDto[] = await hubConnection.invoke("GetSessions");
      const primarySession = plainToInstance(
        SessionInfoDto,
        sessions.find((s) => !s.isPseudoController),
      );
      if (primarySession) {
        debugLog("Received session info: ", primarySession);
        joinSession(primarySession);
      }
    } catch (e) {
      debugLog(`Error connecting to server: ${e}`);
      toast.error(`Error connecting to server: ${e}`);
    }
  }

  useEffect(() => {
    receiveFlightStripsStateRef.current = receiveFlightStripsState;
  }, [receiveFlightStripsState]);

  useEffect(() => {
    if (signalrIsConnected && !facilityId) {
      timeoutFunctionRef.current = setTimeout(() => {
        hubConnectionRef.current.stop();
        dispatch(logout());
      }, SIGNALR_IDLE_TIMEOUT_MS);
    } else {
      clearTimeout(timeoutFunctionRef.current);
    }
  }, [signalrIsConnected, facilityId]);

  useEffect(() => {
    if (nasToken && !hubConnectionRef.current) {
      hubConnectionRef.current = new HubConnectionBuilder()
        .withUrl(OVERRIDE_CLIENT_HUB_URL ?? environment.clientHubUrl, {
          transport: HttpTransportType.WebSockets,
          skipNegotiation: true,
          accessTokenFactory: () => getValidNasToken(),
        })
        .withAutomaticReconnect()
        .build();
    } else if (!nasToken) {
      hubConnectionRef.current = undefined!;
    }
  }, [nasToken]);

  useEffect(() => {
    const hubConnection = hubConnectionRef.current;
    if (!hubConnection || signalrIsConnected) {
      return;
    }

    hubConnection.on("HandleSessionStarted", (sessionInfo) => {
      joinSession(plainToInstance(SessionInfoDto, sessionInfo));
    });

    hubConnection.on("SetSessionActive", (isActive: boolean) => {
      debugLog(`Session is now ${isActive ? "active" : "inactive"}`);
      dispatch(setSessionActive(isActive));
      dispatch(hideContextMenu());
      if (!isActive) {
        dispatch(setSelectedStripItem(undefined));
      }
    });

    hubConnection.on("HandleSessionEnded", () => {
      debugLog("Session ended");
      dispatch(setFacility(undefined));
      dispatch(setSession(undefined));
    });

    hubConnection.on("ReceiveFlightStripsState", (_, state: FlightStripsStateDto) => {
      debugLog("Received new flight strips state: ", state);
      receiveFlightStripsStateRef.current(state);
    });

    hubConnection.on("ReceiveStripItems", (_, stripItems: StripItemDto[]) => {
      stripItems = plainToInstance(StripItemDto, stripItems);
      debugLog("Received new strip items: ", stripItems);
      stripItems.forEach((i) => dispatch(addOrUpdateStripItem(i)));
    });

    hubConnection.on("HandleFsdConnectionStateChanged", (_, isConnected: boolean) => {
      debugLog(isConnected ? "FSD connection reestablished" : "FSD connection lost");
      if (isConnected) {
        toast.success("Successfully reconnected to FSD", { autoClose: 5000 });
        dispatch(setFsdIsConnected(true));
      } else {
        toast.error("Disconnected from FSD. Attempting to reconnect...");
        dispatch(setFsdIsConnected(false));
      }
    });

    hubConnection.onreconnecting((e) => {
      debugLog("Attempting to reconnect");
      toast.error(`${e?.message} Attempting to reconnect...`);
    });

    hubConnection.onreconnected(() => {
      (async () => {
        debugLog("Reconnected");
        await initializeSignalR(false);
        hubConnection.invoke("Subscribe", {
          Category: "FlightStrips",
          FacilityId: facilityIdRef.current,
        });
        toast.dismiss();
        toast.success("Successfully reconnected to server", { autoClose: 5000 });
      })();
    });

    hubConnection.onclose((e) => {
      debugLog(`Disconnected: ${e}`);
      toast.error(e ? e.message : "Disconnected from server");
      dispatch(setFacility(undefined));
      dispatch(setSignalrIsConnected(false));
    });

    initializeSignalR(true);
  }, [nasToken]);

  return <HubContext.Provider value={hubConnectionRef.current}>{children}</HubContext.Provider>;
}

const useHub = () => {
  return useContext(HubContext);
};

export default useHub;
