import { StripItemDto, StripItemType } from "@vatsim-vnas/js-libs/models/vnas/messaging";
import { getNumberOfLines } from "@vatsim-vnas/js-libs/utils";
import React, { ChangeEvent, FocusEvent, KeyboardEvent, MouseEvent, useRef } from "react";
import { ContextMenuState } from "src/enums";
import { useFlightStrips } from "src/hooks";
import {
  hideContextMenu,
  isReadOnlySelector,
  selectedStripItemIdSelector,
  setContextMenuSpec,
  setIsEditingStripItem,
  setSelectedStripItem,
  useAppDispatch,
  useAppSelector,
  wasFocusedFromKeyboardSelector,
} from "src/redux";
import * as S from "src/styles/flightStrips";
import { replaceAnnotationSymbols } from "src/utils";

interface HalfStripProps {
  stripItem: StripItemDto;
}

function HalfStrip({ stripItem }: Readonly<HalfStripProps>) {
  const wasFocusedFromKeyboard = useAppSelector(wasFocusedFromKeyboardSelector);
  const selectedStripItemId = useAppSelector(selectedStripItemIdSelector);
  const isReadOnly = useAppSelector(isReadOnlySelector);
  const dispatch = useAppDispatch();

  const { editStripItemField } = useFlightStrips();
  const leftTextRef = useRef<HTMLTextAreaElement>(undefined!);
  const rightTextRef = useRef<HTMLTextAreaElement>(undefined!);

  const handleContextMenu = (e: MouseEvent) => {
    if (!isReadOnly) {
      e.preventDefault();
      e.stopPropagation();
      dispatch(setSelectedStripItem(stripItem.id));
      dispatch(setContextMenuSpec({ state: ContextMenuState.HalfStrip, x: e.pageX, y: e.pageY }));
    }
  };

  const handleBlur = (e: FocusEvent<HTMLTextAreaElement>, fieldNumber: number) => {
    dispatch(setIsEditingStripItem(false));
    editStripItemField(selectedStripItemId!, fieldNumber, e.target.value);
    if (!wasFocusedFromKeyboard) {
      dispatch(setSelectedStripItem(undefined));
    }
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { selectionStart, selectionEnd, value } = e.target;
    e.target.value = replaceAnnotationSymbols(value);
    e.target.setSelectionRange(selectionStart, selectionEnd);
  };

  const handleTextContextMenu = (e: MouseEvent) => {
    e.stopPropagation();
    dispatch(hideContextMenu());
  };

  const handleFocus = (e: FocusEvent<HTMLTextAreaElement>) => {
    dispatch(setSelectedStripItem(stripItem.id));
    e.target.setSelectionRange(e.target.value.length, e.target.value.length);
    dispatch(setIsEditingStripItem(true));
  };

  const handleKeyDown = (e: KeyboardEvent, textRef: React.MutableRefObject<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.ctrlKey && getNumberOfLines(textRef.current.value) >= 3) {
      e.preventDefault();
    } else if ((e.key === "Enter" && e.ctrlKey) || e.key === "Escape") {
      e.stopPropagation();
      textRef.current.blur();
    }
  };

  return (
    <S.HalfStrip $rightSide={stripItem.type === StripItemType.HalfStripRight} onContextMenu={handleContextMenu}>
      <S.HalfStripText
        defaultValue={stripItem.fieldValues[0]}
        key={stripItem.fieldValues[0]}
        onBlur={(e) => handleBlur(e, 0)}
        onChange={handleChange}
        onContextMenu={handleTextContextMenu}
        onFocus={handleFocus}
        onKeyDown={(e) => handleKeyDown(e, leftTextRef)}
        onMouseDown={(e) => e.stopPropagation()}
        ref={leftTextRef}
        spellCheck="false"
      />
      <S.HalfStripText
        defaultValue={stripItem.fieldValues[1]}
        key={stripItem.fieldValues[1]}
        onBlur={(e) => handleBlur(e, 1)}
        onChange={handleChange}
        onContextMenu={handleTextContextMenu}
        onFocus={handleFocus}
        onKeyDown={(e) => handleKeyDown(e, rightTextRef)}
        onMouseDown={(e) => e.stopPropagation()}
        ref={rightTextRef}
        spellCheck="false"
      />
    </S.HalfStrip>
  );
}

export default HalfStrip;
