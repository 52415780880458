import { getEventCoordinates } from "@vatsim-vnas/js-libs/utils";
import React, { useEffect } from "react";
import { useFlightStrips } from "src/hooks";
import {
  activeBayIdSelector,
  animationsDisabledSelector,
  configurationSelector,
  displacingPositionSelector,
  draggingStripItemSpecSelector,
  facilityIdSelector,
  hoveringOverBayButtonIdSelector,
  hoveringOverTrashSelector,
  isDraggingStripItemSelector,
  setActiveBay,
  setAnimationsDisabled,
  setDisplacingPosition,
  setHoveringOverBayButton,
  setHoveringOverTrash,
  useAppDispatch,
  useAppSelector,
} from "src/redux";
import * as S from "src/styles/flightStrips";
import { HOVER_ACTIVATE_DURATION_MS, SCROLL_ZONE_SIZE } from "src/utils";

let scrollYInterval = 0;
let scrollXInterval = 0;
let hoverTimeout: NodeJS.Timeout;

function Draggable() {
  const draggingStripItemSpec = useAppSelector(draggingStripItemSpecSelector);
  const displacedPosition = useAppSelector(displacingPositionSelector);
  const hoveringOverBayButtonId = useAppSelector(hoveringOverBayButtonIdSelector);
  const hoveringOverTrash = useAppSelector(hoveringOverTrashSelector);
  const animationsDisabled = useAppSelector(animationsDisabledSelector);
  const isDraggingStripItem = useAppSelector(isDraggingStripItemSelector);
  const activeBayId = useAppSelector(activeBayIdSelector);
  const facilityId = useAppSelector(facilityIdSelector)!;
  const configuration = useAppSelector(configurationSelector)!;
  const dispatch = useAppDispatch();
  const { getDraggingStripElement, returnDraggingStripItem, moveDraggingStripItem, getFirstPositionOnRack, deleteDraggingStripItem } =
    useFlightStrips();

  const makeTranslucent = (overElements: Element[], stripElement: HTMLDivElement) => {
    if (overElements.find((el) => el.classList.contains("translucent-draggable"))) {
      stripElement.style.opacity = "0.5";
    } else {
      stripElement.style.opacity = "1";
    }
  };

  const displaceStripItems = (overElements: Element[]) => {
    const overStripItemPlaceholder = overElements.find((el) => el.hasAttribute("data-strip-item-placeholder-index"));
    if (overStripItemPlaceholder) {
      const rack = parseInt(overElements.find((el) => el.hasAttribute("data-rack-number"))!.getAttribute("data-rack-number")!, 10);
      const index = parseInt(overStripItemPlaceholder.getAttribute("data-strip-item-placeholder-index")!, 10);
      if (displacedPosition?.rack !== rack || displacedPosition.index !== index) {
        dispatch(setDisplacingPosition({ bayId: activeBayId, rack, index, facilityId }));
      }
    } else if (displacedPosition) {
      dispatch(setDisplacingPosition(undefined));
    }
  };

  const scrollRacks = (overElements: Element[], y: number) => {
    clearInterval(scrollYInterval);
    const rack = overElements.find((el) => el.hasAttribute("data-rack-number"));
    if (rack) {
      const topOffset = y - rack.getBoundingClientRect().top;
      const bottomOffset = rack.getBoundingClientRect().bottom - y;

      if (topOffset <= SCROLL_ZONE_SIZE) {
        scrollYInterval = window.setInterval(() => {
          rack.scrollBy(0, (-5 * (SCROLL_ZONE_SIZE - topOffset)) / SCROLL_ZONE_SIZE);
        }, 5);
      } else if (bottomOffset <= SCROLL_ZONE_SIZE) {
        scrollYInterval = window.setInterval(() => {
          rack.scrollBy(0, (5 * (SCROLL_ZONE_SIZE - bottomOffset)) / SCROLL_ZONE_SIZE);
        }, 5);
      }
    }
  };

  const scrollBay = (x: number) => {
    clearInterval(scrollXInterval);
    const bayElement = document.getElementById("bay")!;
    const leftOffset = x - bayElement.getBoundingClientRect().left;
    const rightOffset = bayElement.getBoundingClientRect().right - x;

    if (leftOffset <= SCROLL_ZONE_SIZE) {
      scrollXInterval = window.setInterval(() => {
        bayElement.scrollBy((-5 * (SCROLL_ZONE_SIZE - leftOffset)) / SCROLL_ZONE_SIZE, 0);
      }, 5);
    } else if (rightOffset <= SCROLL_ZONE_SIZE) {
      scrollXInterval = window.setInterval(() => {
        bayElement.scrollBy((5 * (SCROLL_ZONE_SIZE - rightOffset)) / SCROLL_ZONE_SIZE, 0);
      }, 5);
    }
  };

  const hoverOverBayButtons = (overElements: Element[]) => {
    const trash = overElements.find((el) => el.hasAttribute("data-trash"));
    const bayButton = overElements.find((el) => el.hasAttribute("data-bay-button-id"));
    const externalBayButton = overElements.find((el) => el.hasAttribute("data-bay-button-external-facility-id"));

    if (draggingStripItemSpec!.deletable && trash) {
      dispatch(setHoveringOverTrash(true));
      if (hoveringOverBayButtonId) {
        clearTimeout(hoverTimeout);
        dispatch(setHoveringOverBayButton(undefined));
      }
      return;
    }

    if (bayButton && !externalBayButton) {
      const bayId = bayButton.getAttribute("data-bay-button-id")!;
      if (bayId !== activeBayId && hoveringOverBayButtonId !== bayId) {
        dispatch(setHoveringOverBayButton(bayId));
        hoverTimeout = setTimeout(() => {
          dispatch(setActiveBay(bayId));
        }, HOVER_ACTIVATE_DURATION_MS);
      }
      return;
    }

    if (hoveringOverTrash) {
      dispatch(setHoveringOverTrash(false));
    }

    if (hoveringOverBayButtonId) {
      clearTimeout(hoverTimeout);
      dispatch(setHoveringOverBayButton(undefined));
    }
  };

  const handleMoveStrip = (e: MouseEvent) => {
    const { x, y } = getEventCoordinates(e);

    if (animationsDisabled) {
      dispatch(setAnimationsDisabled(false));
    }
    const { stripItemId, xOffset, yOffset } = draggingStripItemSpec!;
    const stripElement = getDraggingStripElement(stripItemId);

    stripElement.style.left = `${x - xOffset}px`;
    stripElement.style.top = `${y - yOffset}px`;

    const overElements = document.elementsFromPoint(x, y);

    makeTranslucent(overElements, stripElement);

    displaceStripItems(overElements);

    scrollRacks(overElements, y);

    scrollBay(x);

    hoverOverBayButtons(overElements);
  };

  const handleDropStrip = (e: MouseEvent) => {
    const { x, y } = getEventCoordinates(e);
    if (e.button === 0) {
      clearInterval(scrollYInterval);
      clearInterval(scrollXInterval);
      clearTimeout(hoverTimeout);
      dispatch(setAnimationsDisabled(true));
      dispatch(setHoveringOverBayButton(undefined));
      dispatch(setHoveringOverTrash(false));

      const droppedOnElements = document.elementsFromPoint(x, y);
      const droppedOnTrash = droppedOnElements.find((el) => el.hasAttribute("data-trash"));
      const droppedOnExternalBayButton = droppedOnElements.find((el) => el.hasAttribute("data-bay-button-external-facility-id"));
      const droppedOnBayButton = droppedOnElements.find((el) => el.hasAttribute("data-bay-button-id"));
      const droppedOnRack = droppedOnElements.find((el) => el.hasAttribute("data-rack-number"));
      const droppedOnPlaceholder = droppedOnElements.find((el) => el.hasAttribute("data-strip-item-placeholder-index"));
      if (draggingStripItemSpec!.deletable && droppedOnTrash) {
        deleteDraggingStripItem();
      } else if (droppedOnExternalBayButton) {
        const externalFacilityId = droppedOnExternalBayButton.getAttribute("data-bay-button-external-facility-id")!;
        const bayId = droppedOnExternalBayButton.getAttribute("data-bay-button-id")!;
        moveDraggingStripItem({ bayId, rack: 0, index: -1, facilityId: externalFacilityId }, true);
      } else if (droppedOnBayButton) {
        const bayId = droppedOnBayButton.getAttribute("data-bay-button-id")!;
        if (bayId === activeBayId) {
          returnDraggingStripItem();
        } else {
          moveDraggingStripItem(getFirstPositionOnRack(bayId, (configuration.stripBays.find((b) => b.id === bayId)!.defaultRack ?? 1) - 1), true);
        }
      } else if (droppedOnPlaceholder) {
        const rack = parseInt(droppedOnRack!.getAttribute("data-rack-number")!, 10);
        const index = parseInt(droppedOnPlaceholder.getAttribute("data-strip-item-placeholder-index")!, 10);
        moveDraggingStripItem({ bayId: activeBayId, rack, index, facilityId });
      } else if (droppedOnRack) {
        const rack = droppedOnRack.getAttribute("data-rack-number")!;
        moveDraggingStripItem(getFirstPositionOnRack(activeBayId, parseInt(rack, 10)));
      } else {
        returnDraggingStripItem();
      }
    } else {
      e.preventDefault();
    }
  };

  const blockEvent = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  useEffect(() => {
    if (isDraggingStripItem && getDraggingStripElement(draggingStripItemSpec!.stripItemId)) {
      document.addEventListener("mousemove", handleMoveStrip);
      document.addEventListener("mouseup", handleDropStrip);
      document.addEventListener("mousedown", blockEvent);
      document.addEventListener("contextmenu", blockEvent);
    }

    return () => {
      document.removeEventListener("mousemove", handleMoveStrip);
      document.removeEventListener("mouseup", handleDropStrip);
      document.removeEventListener("mousedown", blockEvent);
      document.removeEventListener("contextmenu", blockEvent);
    };
  }, [isDraggingStripItem, handleMoveStrip, handleDropStrip]);

  return (
    <>
      <S.CloneContainer id="draggable-clone-container" />
      {isDraggingStripItem && <S.DraggingScreen />}
    </>
  );
}
export default Draggable;
