import { faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  environmentSelector,
  environmentsSelector,
  setEnvironment,
  useAppDispatch,
  useAppSelector,
  vatsimCodeSelector,
  vatsimTokenSelector,
} from "src/redux";
import * as S from "src/styles/ui";
import { DOMAIN, VATSIM_CLIENT_ID } from "src/utils";

const REDIRECT_URL = encodeURIComponent(`${DOMAIN}/login`);
const VATSIM_LOGIN_URL = `https://auth.vatsim.net/oauth/authorize?client_id=${VATSIM_CLIENT_ID}&redirect_uri=${REDIRECT_URL}&response_type=code&scope=vatsim_details`;

function VatsimLogin() {
  const vatsimCode = useAppSelector(vatsimCodeSelector);
  const vatsimToken = useAppSelector(vatsimTokenSelector);
  const environments = useAppSelector(environmentsSelector);
  const environment = useAppSelector(environmentSelector);
  const dispatch = useAppDispatch();

  const [isLoggingIn, setIsLoggingIn] = useState(false);

  useEffect(() => {
    setIsLoggingIn(!!vatsimCode || !!vatsimToken);
  }, [vatsimCode, vatsimToken]);

  return (
    <>
      <img src="/img/logo.png" alt="vStrips" height="100" width="100" />
      <S.Title>vStrips</S.Title>
      <S.Select
        disabled={isLoggingIn}
        defaultValue={environment?.name}
        key={environment?.name}
        onChange={(e) => dispatch(setEnvironment(e.target.value))}
      >
        {environments.map((e) => (
          <option key={e.name}>{e.name}</option>
        ))}
      </S.Select>
      <S.Button
        className="success"
        disabled={isLoggingIn}
        onClick={() => {
          setIsLoggingIn(true);
          window.location.href = VATSIM_LOGIN_URL;
        }}
      >
        {isLoggingIn ? <FontAwesomeIcon icon={faGear} className="fa-spin" /> : "Login with VATSIM"}
      </S.Button>
    </>
  );
}

export default VatsimLogin;
