import React from "react";
import * as S from "src/styles/login";
import { Subtitle, Title } from "src/styles/ui";
import { VERSION } from "src/utils";

function RedirectIosSidebar() {
  return (
    <S.LoginSidebar>
      <S.LoginPanel>
        <img src="/img/logo.png" alt="vStrips" height="100" width="100" />
        <Title>vStrips</Title>
        <Subtitle>vStrips is not supported on iOS. An iOS App is currently in development.</Subtitle>
      </S.LoginPanel>
      <S.Footer>
        <b>Version</b>
        <span>&nbsp;{VERSION}</span>
      </S.Footer>
    </S.LoginSidebar>
  );
}

export default RedirectIosSidebar;
