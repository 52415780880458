import { StripItemDto, isLockableSeparator } from "@vatsim-vnas/js-libs/models/vnas/messaging";
import React, { FocusEvent, KeyboardEvent, MouseEvent, useRef } from "react";
import { ContextMenuState } from "src/enums";
import { useFlightStrips } from "src/hooks";
import {
  isReadOnlySelector,
  setContextMenuSpec,
  setIsEditingStripItem,
  setSelectedStripItem,
  useAppDispatch,
  useAppSelector,
  wasFocusedFromKeyboardSelector,
} from "src/redux";
import * as S from "src/styles/flightStrips";

interface SeparatorProps {
  stripItem: StripItemDto;
}

function Separator({ stripItem }: Readonly<SeparatorProps>) {
  const isReadOnly = useAppSelector(isReadOnlySelector);
  const wasFocusedFromKeyboard = useAppSelector(wasFocusedFromKeyboardSelector);
  const dispatch = useAppDispatch();

  const { editStripItemField } = useFlightStrips();
  const inputRef = useRef<HTMLInputElement>(undefined!);

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    dispatch(setIsEditingStripItem(false));
    editStripItemField(stripItem.id, 0, e.target.value);
    if (!wasFocusedFromKeyboard) {
      dispatch(setSelectedStripItem(undefined));
    }
  };

  const handleContextMenu = (e: MouseEvent) => {
    if (!isReadOnly) {
      e.preventDefault();
      e.stopPropagation();
      dispatch(setSelectedStripItem(stripItem.id));
      dispatch(
        setContextMenuSpec({
          state: isLockableSeparator(stripItem.type) ? ContextMenuState.LockableSeparator : ContextMenuState.Separator,
          x: e.pageX,
          y: e.pageY,
        }),
      );
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter" || e.key === "Escape") {
      e.stopPropagation();
      inputRef.current.blur();
    }
  };

  return (
    <S.Separator type={stripItem.type} onContextMenu={handleContextMenu}>
      <S.SeparatorLabel
        defaultValue={stripItem.fieldValues[0]}
        key={stripItem.fieldValues[0]}
        onBlur={handleBlur}
        onFocus={() => dispatch(setIsEditingStripItem(true))}
        onKeyDown={handleKeyDown}
        ref={inputRef}
        type={stripItem.type}
      />
    </S.Separator>
  );
}

export default Separator;
