import { faPrint, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StripItemType } from "@vatsim-vnas/js-libs/models/vnas/messaging";
import React from "react";
import { useFlightStrips } from "src/hooks";
import {
  configurationSelector,
  facilityIdSelector,
  hoveringOverTrashSelector,
  printerItemIdsSelector,
  setFacilityMenuIsActive,
  setPrinterMenuIsActive,
  useAppDispatch,
  useAppSelector,
} from "src/redux";
import * as S from "src/styles/header";
import BayButton from "./BayButton";

function Header() {
  const dispatch = useAppDispatch();
  const facilityId = useAppSelector(facilityIdSelector);
  const configuration = useAppSelector(configurationSelector);
  const printerItemIds = useAppSelector(printerItemIdsSelector);
  const isHoveringOverTrash = useAppSelector(hoveringOverTrashSelector);
  const { getStripItem } = useFlightStrips();

  const departurePrinterItemCount = printerItemIds.filter((i) => getStripItem(i).type !== StripItemType.ArrivalStrip).length;
  const arrivalPrinterItemCount = printerItemIds.filter((i) => getStripItem(i).type === StripItemType.ArrivalStrip).length;

  const alertString =
    configuration?.enableArrivalStrips && configuration?.enableSeparateArrDepPrinters
      ? `${departurePrinterItemCount}/${arrivalPrinterItemCount}`
      : printerItemIds.length.toString();

  return (
    <S.Header className="translucent-draggable">
      <S.HeaderGroup>
        <S.HeaderButton onClick={() => dispatch(setFacilityMenuIsActive(true))}>{facilityId}</S.HeaderButton>
      </S.HeaderGroup>
      {configuration && (
        <S.BayButtonsContainer>
          <>
            {configuration.stripBays.map((b, i) => (
              <BayButton bayId={b.id} bayIndex={i} name={b.name} key={b.id} />
            ))}
            {configuration.externalBays.map((b, i) => (
              <BayButton bayId={b.bayId} bayIndex={i} facility={b.facilityId} key={b.bayId} />
            ))}
          </>
        </S.BayButtonsContainer>
      )}
      <S.HeaderGroup>
        <S.TrashButton data-trash $isHovering={isHoveringOverTrash}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </S.TrashButton>
        <S.HeaderButton onClick={() => dispatch(setPrinterMenuIsActive(true))}>
          <FontAwesomeIcon icon={faPrint} />
          {!!printerItemIds.length && <S.AlertNumber $addPadding={alertString.length > 3}>{alertString}</S.AlertNumber>}
        </S.HeaderButton>
      </S.HeaderGroup>
    </S.Header>
  );
}

export default Header;
