import { useEffect, useRef } from "react";
import { ContextMenuState } from "src/enums";
import {
  contextMenuSpecSelector,
  facilityMenuIsActiveSelector,
  isDraggingStripItemSelector,
  isEditingStripItemSelector,
  isReadOnlySelector,
  printerMenuIsActiveSelector,
  selectedStripItemIdSelector,
  useAppSelector,
} from "src/redux";

interface KeyOptions {
  ctrl?: boolean;
  shift?: boolean;
  alt?: boolean;
  stripItemIsSelected?: boolean;
  isReadOnly?: boolean;
  printerMenuIsActive?: boolean;
  facilityMenuIsActive?: boolean;
  contextMenuIsActive?: boolean;
  alternateKeys?: string[];
  disabled?: boolean;
}

function optionIsSatisfied(option: boolean | undefined, eventValue: boolean) {
  return option === undefined || option === eventValue;
}

function defaultFalseOptionIsSatisfied(option: boolean | undefined, eventValue: boolean) {
  return (option === undefined && !eventValue) || option === eventValue;
}

const useKey = (key: string, callback: (event: KeyboardEvent) => void, options?: KeyOptions) => {
  const isReadOnly = useAppSelector(isReadOnlySelector);
  const printerMenuIsActive = useAppSelector(printerMenuIsActiveSelector);
  const facilityMenuIsActive = useAppSelector(facilityMenuIsActiveSelector);
  const contextMenuIsActive = useAppSelector(contextMenuSpecSelector).state !== ContextMenuState.Hidden;
  const stripItemIsSelected = !!useAppSelector(selectedStripItemIdSelector);
  const isEditingStripItem = useAppSelector(isEditingStripItemSelector);
  const isDraggingStripItem = useAppSelector(isDraggingStripItemSelector);

  const isLocked = useRef(false);

  function optionsAreSatisfied(e: KeyboardEvent) {
    if (!options) {
      return !printerMenuIsActive && !facilityMenuIsActive && !contextMenuIsActive;
    }
    if (!optionIsSatisfied(options.ctrl, e.ctrlKey)) {
      return false;
    }
    if (!optionIsSatisfied(options.shift, e.shiftKey)) {
      return false;
    }
    if (!optionIsSatisfied(options.alt, e.altKey)) {
      return false;
    }
    if (!optionIsSatisfied(options.isReadOnly, isReadOnly)) {
      return false;
    }
    if (!optionIsSatisfied(options.stripItemIsSelected, stripItemIsSelected)) {
      return false;
    }
    if (!defaultFalseOptionIsSatisfied(options.printerMenuIsActive, printerMenuIsActive)) {
      return false;
    }
    if (!defaultFalseOptionIsSatisfied(options.facilityMenuIsActive, facilityMenuIsActive)) {
      return false;
    }
    if (!defaultFalseOptionIsSatisfied(options.contextMenuIsActive, contextMenuIsActive)) {
      return false;
    }
    return true;
  }

  function handleKeyDown(e: KeyboardEvent) {
    if (
      !isLocked.current &&
      (e.key === key || (options?.alternateKeys && options.alternateKeys.includes(e.key))) &&
      !isEditingStripItem &&
      !isDraggingStripItem &&
      optionsAreSatisfied(e)
    ) {
      isLocked.current = true;
      e.preventDefault();
      callback(e);
    }
  }

  function handleKeyUp() {
    isLocked.current = false;
  }

  useEffect(() => {
    if (!options?.disabled) {
      document.addEventListener("keydown", handleKeyDown);
      document.addEventListener("keyup", handleKeyUp);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [callback, options]);
};

export default useKey;
