import styled, { css } from "styled-components";
import { COLOR_DANGER } from "./colors";

export const Header = styled.div`
  width: 100vw;
  height: 76px;
  z-index: 50;
  position: fixed;
  background-color: #1d1e22;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderGroup = styled.div`
  display: flex;
  gap: 10px;
  margin: 20px;
`;

export const HeaderButton = styled.div`
  text-align: center;
  height: fit-content;
  color: white;
  font-size: 24px;
  min-width: 32px;
  padding: 5px 10px;
  background-color: #ffffff12;
  border: 1px solid white;
  cursor: pointer;
  flex-shrink: 0;
  position: relative;
`;

export const TrashButton = styled(HeaderButton)<{ $isHovering?: boolean }>`
  cursor: default;

  ${(p) =>
    p.$isHovering &&
    css`
      animation: trashBorderBlink 0.5s step-end infinite;
      color: ${COLOR_DANGER};
    `}

  @keyframes trashBorderBlink {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: ${COLOR_DANGER};
    }
  }
`;

export const BayButtonsContainer = styled.div`
  overflow-x: auto;
  display: flex;
  gap: 16px;
  padding-bottom: 3px;

  &::-webkit-scrollbar {
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: grey;
    &:hover {
      background: #6b6b6b;
    }
  }
`;

export const BayButton = styled.button<{ $selected?: boolean; $isExternal?: boolean; $isHovering?: boolean }>`
  white-space: nowrap;
  min-width: 185px;
  background-color: grey;
  border: 1px solid white;
  border-style: solid solid inset;
  color: white;
  font-size: 20px;
  padding: 15px 15px;
  flex-shrink: 0;
  border-width: ${(p) => (p.$selected || p.$isHovering ? "5px" : "1px")};
  position: relative;

  ${(p) =>
    p.$isExternal &&
    css`
      cursor: default;
    `}

  ${(p) =>
    !p.$isExternal &&
    !p.$selected &&
    css`
      cursor: pointer;
      &:active {
        background-color: #6b6b6b;
      }
    `}

    ${(p) =>
    p.$isHovering &&
    css`
      animation: borderBlink 0.5s step-end infinite;
    `}

  @keyframes borderBlink {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: white;
    }
  }
`;

export const BayIndex = styled.small`
  position: absolute;
  left: 50%;
  bottom: 5px;
  transform: translateX(-50%);
  font-size: 14px;
`;

export const AlertNumber = styled.div<{ $addPadding: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  min-width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${COLOR_DANGER};

  display: flex;
  justify-content: center;
  align-items: center;
  ${(p) =>
    p.$addPadding &&
    css`
      padding: 0 3px;
    `}
  font-size: 12px;
  color: white;
`;
