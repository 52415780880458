export const VERSION = process.env.REACT_APP_VERSION!;
export const DOMAIN = process.env.REACT_APP_DOMAIN!;
export const VATSIM_CLIENT_ID = process.env.REACT_APP_VATSIM_CLIENT_ID!;
export const DEBUG_MODE = process.env.REACT_APP_DEBUG_MODE === "true";
export const OVERRIDE_API_URL = process.env.REACT_APP_OVERRIDE_API_URL;
export const OVERRIDE_CLIENT_HUB_URL = process.env.REACT_APP_OVERRIDE_CLIENT_HUB_URL;

export const SIGNALR_IDLE_TIMEOUT_MS = 900000; // 15 minutes
export const DISPLACE_ANIMATION_MS = 200;
export const DROP_ANIMATION_MS = 400;
export const SCROLL_ZONE_SIZE = 150;
export const HOVER_ACTIVATE_DURATION_MS = 1000;
