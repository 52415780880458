import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { getStripBayAsync } from "@vatsim-vnas/js-libs/api/data";
import { usePrompt } from "@vatsim-vnas/js-libs/hooks";
import { StripItemDto, StripItemType } from "@vatsim-vnas/js-libs/models/vnas/messaging";
import { cycleNextItemInArray, cyclePreviousItemInArray } from "@vatsim-vnas/js-libs/utils";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Draggable, Header, StripRack } from "src/components/flightStrips";
import { ContextMenu, FacilityMenu, PrinterMenu } from "src/components/ui";
import { Direction } from "src/enums";
import { useFlightStrips, useKey } from "src/hooks";
import { StripItemPosition } from "src/models";
import {
  activeBayIdSelector,
  addExternalStripBay,
  baysContentsSelector,
  configurationSelector,
  flightStripsFacilitiesSelector,
  removeExternalStripBay,
  selectedStripItemIdSelector,
  sessionSelector,
  setFacilityMenuIsActive,
  setPrinterMenuIsActive,
  setSelectedStripItem,
  setWasFocusedFromKeyboard,
  useAppDispatch,
  useAppSelector,
} from "src/redux";
import * as S from "src/styles/flightStrips";
import { getFieldNumber } from "src/utils";

function FlightStrips() {
  const facilityId = useParams().id!;
  const flightStripsFacilities = useAppSelector(flightStripsFacilitiesSelector);
  const baysContents = useAppSelector(baysContentsSelector);
  const activeBayId = useAppSelector(activeBayIdSelector);
  const session = useAppSelector(sessionSelector)!;
  const configuration = useAppSelector(configurationSelector);
  const selectedStripItemId = useAppSelector(selectedStripItemIdSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    getBayId,
    getBay,
    setActiveBay,
    cycleActiveBay,
    getFirstPositionOnRack,
    getStripItemPosition,
    selectAdjacentStripItem,
    focusStripItemField,
    deleteStripItem,
    cycleStripItemType,
    createStripItem,
    moveSelectedStripItem,
    pushStripItemToBay,
    toggleStripItemOffset,
    setFacility,
  } = useFlightStrips();

  usePrompt();

  useEffect(() => {
    setFacility(facilityId);
  }, [facilityId]);

  useEffect(() => {
    (async () => {
      if (configuration) {
        setActiveBay(configuration.stripBays[0].id);
        const promises = configuration.externalBays.map(async (b) => {
          const res = await getStripBayAsync(session.artccId, b.facilityId, b.bayId);
          if (res.ok) {
            dispatch(addExternalStripBay(res.data!));
          } else {
            dispatch(removeExternalStripBay(b.bayId));
          }
        });
        await Promise.all(promises);
      }
    })();
  }, [configuration]);

  const cycleFacility = (next: boolean) => {
    if (flightStripsFacilities.length < 2) {
      return;
    }
    const index = flightStripsFacilities.findIndex((f) => f === facilityId)!;
    const nextFacilityId = next ? cycleNextItemInArray(flightStripsFacilities, index) : cyclePreviousItemInArray(flightStripsFacilities, index);
    navigate(`/${nextFacilityId}`, { replace: true });
  };

  const focusField = (fieldNumber: number) => {
    dispatch(setWasFocusedFromKeyboard(true));
    focusStripItemField(selectedStripItemId!, fieldNumber);
  };

  const handleCreateStripItem = (stripItemType: StripItemType) => {
    dispatch(setWasFocusedFromKeyboard(true));
    const stripItem = new StripItemDto(stripItemType);
    let position: StripItemPosition;
    if (selectedStripItemId) {
      position = getStripItemPosition(selectedStripItemId)!;
      position.index++;
    } else {
      position = getFirstPositionOnRack(activeBayId, 0);
    }
    createStripItem(stripItem, position);
  };

  useKey("Tab", () => dispatch(setPrinterMenuIsActive(true)));
  useKey("Escape", () => dispatch(setFacilityMenuIsActive(true)), { stripItemIsSelected: false });
  useKey("Escape", () => dispatch(setSelectedStripItem(undefined)), { stripItemIsSelected: true });

  useKey("PageDown", () => cycleActiveBay(false));
  useKey("PageUp", () => cycleActiveBay(true));
  useKey("1", (e) => setActiveBay(getBayId(parseInt(e.key, 10) - 1)), {
    stripItemIsSelected: false,
    ctrl: true,
    alt: true,
    alternateKeys: ["2", "3", "4", "5", "6", "7", "8", "9", "0"],
  });

  useKey("ArrowUp", () => selectAdjacentStripItem(Direction.Up), { ctrl: false, shift: false, alt: false, isReadOnly: false });
  useKey("ArrowDown", () => selectAdjacentStripItem(Direction.Down), { ctrl: false, shift: false, alt: false, isReadOnly: false });
  useKey("ArrowLeft", () => selectAdjacentStripItem(Direction.Left), { ctrl: false, shift: false, alt: false, isReadOnly: false });
  useKey("ArrowRight", () => selectAdjacentStripItem(Direction.Right), { ctrl: false, shift: false, alt: false, isReadOnly: false });

  useKey("Delete", () => deleteStripItem(selectedStripItemId!), { stripItemIsSelected: true, isReadOnly: false, alternateKeys: ["Backspace"] });
  useKey("Enter", () => focusField(0), { stripItemIsSelected: true, isReadOnly: false });
  useKey("1", (e) => focusField(getFieldNumber(e)), {
    stripItemIsSelected: true,
    ctrl: true,
    alt: false,
    isReadOnly: false,
    alternateKeys: ["2", "3", "4", "5", "6", "7", "8", "9"],
  });

  useKey("ArrowRight", () => cycleStripItemType(selectedStripItemId!, true), {
    stripItemIsSelected: true,
    ctrl: true,
    shift: true,
    alt: false,
    isReadOnly: false,
  });
  useKey("ArrowLeft", () => cycleStripItemType(selectedStripItemId!, false), {
    stripItemIsSelected: true,
    ctrl: true,
    shift: true,
    alt: false,
    isReadOnly: false,
  });

  useKey("ArrowRight", () => toggleStripItemOffset(selectedStripItemId!), {
    stripItemIsSelected: true,
    ctrl: false,
    shift: true,
    alt: false,
    isReadOnly: false,
  });
  useKey("ArrowLeft", () => toggleStripItemOffset(selectedStripItemId!), {
    stripItemIsSelected: true,
    ctrl: false,
    shift: true,
    alt: false,
    isReadOnly: false,
  });

  useKey("H", () => handleCreateStripItem(StripItemType.HalfStripLeft), { ctrl: true, shift: true, alt: false, isReadOnly: false });
  useKey("S", () => handleCreateStripItem(StripItemType.HandwrittenSeparator), { ctrl: true, shift: true, alt: false, isReadOnly: false });

  useKey("ArrowUp", () => moveSelectedStripItem(Direction.Up), {
    ctrl: true,
    shift: false,
    alt: false,
    isReadOnly: false,
    stripItemIsSelected: true,
  });
  useKey("ArrowDown", () => moveSelectedStripItem(Direction.Down), {
    ctrl: true,
    shift: false,
    alt: false,
    isReadOnly: false,
    stripItemIsSelected: true,
  });
  useKey("ArrowRight", () => moveSelectedStripItem(Direction.Right), {
    ctrl: true,
    shift: false,
    alt: false,
    isReadOnly: false,
    stripItemIsSelected: true,
  });
  useKey("ArrowLeft", () => moveSelectedStripItem(Direction.Left), {
    ctrl: true,
    shift: false,
    alt: false,
    isReadOnly: false,
    stripItemIsSelected: true,
  });

  useKey("1", (e) => pushStripItemToBay(selectedStripItemId!, getBayId(parseInt(e.key, 10) - 1)), {
    stripItemIsSelected: true,
    ctrl: true,
    alt: true,
    isReadOnly: false,
    alternateKeys: ["2", "3", "4", "5", "6", "7", "8", "9"],
  });

  useKey("ArrowLeft", () => cycleFacility(false), { ctrl: true, alt: true });
  useKey("ArrowRight", () => cycleFacility(true), { ctrl: true, alt: true });

  const loaded = configuration && activeBayId && baysContents.length && getBay(activeBayId);

  return (
    <>
      <Header />
      <S.Bay id="bay">
        {loaded ? (
          // eslint-disable-next-line react/no-array-index-key
          getBay(activeBayId).itemIds.map((r, i) => <StripRack itemIds={r} rackNumber={i} key={i} />)
        ) : (
          <S.Spinner icon={faSpinner} className="fa-spin" />
        )}
      </S.Bay>
      <Draggable />
      <ContextMenu />
      <FacilityMenu />
      <PrinterMenu />
    </>
  );
}

export default FlightStrips;
