import { separatorTypeToString, StripItemDto, StripItemType } from "@vatsim-vnas/js-libs/models/vnas/messaging";
import React, { ReactNode, useEffect, useRef } from "react";
import { ContextMenuState } from "src/enums";
import { useFlightStrips } from "src/hooks";
import {
  activeBayIdSelector,
  configurationSelector,
  contextMenuSpecSelector,
  externalBaysSelector,
  hideContextMenu as hideContextMenuInState,
  isReadOnlySelector,
  selectedStripItemIdSelector,
  setContextMenuSpec,
  setSelectedStripItem,
  setWasFocusedFromKeyboard,
  useAppDispatch,
  useAppSelector,
} from "src/redux";
import * as S from "src/styles/menus";

function ContextMenu() {
  const isReadOnly = useAppSelector(isReadOnlySelector);
  const configuration = useAppSelector(configurationSelector)!;
  const activeBayId = useAppSelector(activeBayIdSelector);
  const externalBays = useAppSelector(externalBaysSelector);
  const selectedStripItemId = useAppSelector(selectedStripItemIdSelector);
  const contextMenuSpec = useAppSelector(contextMenuSpecSelector);
  const dispatch = useAppDispatch();

  const isCreatingStripItem = useRef(true);
  const {
    deleteStripItem,
    createStripItem,
    getStripItemPosition,
    getFirstPositionOnRack,
    cycleStripItemType,
    focusStripItemField,
    pushStripItemToBay,
    editStripItemType,
    toggleStripItemOffset,
  } = useFlightStrips();
  const contextMenuRef = useRef<HTMLDivElement>(undefined!);

  const hideContextMenu = (e?: MouseEvent | KeyboardEvent) => {
    if (!e) {
      dispatch(hideContextMenuInState());
      dispatch(setSelectedStripItem(undefined));
    } else if (e instanceof MouseEvent && e.button !== 2 && !document.elementsFromPoint(e.pageX, e.pageY).some((el) => el.id === "context-menu")) {
      hideContextMenu();
    } else if (e instanceof KeyboardEvent) {
      if (e.key === "Escape") {
        hideContextMenu();
      } else if (e.key === "Tab") {
        e.preventDefault();
      }
    }
  };

  useEffect(() => {
    const contextMenuElement = contextMenuRef.current;
    if (!isReadOnly && contextMenuSpec.state !== ContextMenuState.Hidden) {
      contextMenuElement.style.left = `${contextMenuSpec.x!}px`;
      contextMenuElement.style.top = `${contextMenuSpec.y!}px`;

      document.addEventListener("mousedown", hideContextMenu);
      document.addEventListener("contextmenu", hideContextMenu);
      document.addEventListener("keydown", hideContextMenu);
      document.addEventListener("wheel", hideContextMenu);
    }

    return () => {
      document.removeEventListener("mousedown", hideContextMenu);
      document.removeEventListener("contextmenu", hideContextMenu);
      document.removeEventListener("keydown", hideContextMenu);
      document.removeEventListener("wheel", hideContextMenu);
    };
  }, [contextMenuSpec]);

  useEffect(() => {
    const contextMenuElement = contextMenuRef.current;
    if (contextMenuElement && contextMenuSpec.state !== ContextMenuState.Hidden) {
      const { top, bottom, left, right } = contextMenuElement.getBoundingClientRect();
      if (bottom > window.innerHeight) {
        contextMenuElement.style.top = `${top - contextMenuElement.clientHeight}px`;
      }
      if (right > window.innerWidth) {
        contextMenuElement.style.left = `${left - contextMenuElement.clientWidth}px`;
      }
    }
  }, [contextMenuSpec]);

  const addHalfStripMenuItem = (
    <S.ContextMenuItem
      key="add-half-strips"
      onClick={() => {
        dispatch(setWasFocusedFromKeyboard(false));
        const stripItem = new StripItemDto(StripItemType.HalfStripLeft);
        const position = selectedStripItemId
          ? getStripItemPosition(selectedStripItemId)!
          : getFirstPositionOnRack(activeBayId, contextMenuSpec.selectedRack!);
        createStripItem(stripItem, position);
        hideContextMenu();
      }}
    >
      Add half-strip
    </S.ContextMenuItem>
  );

  const deleteMenuItem = (
    <S.ContextMenuItem
      key="delete"
      onClick={() => {
        deleteStripItem(selectedStripItemId!);
        hideContextMenu();
      }}
    >
      Delete
    </S.ContextMenuItem>
  );

  const offsetMenuItem = (
    <S.ContextMenuItem
      key="offset"
      onClick={() => {
        toggleStripItemOffset(selectedStripItemId!);
        hideContextMenu();
      }}
    >
      Offset
    </S.ContextMenuItem>
  );

  const slideMenuItem = (
    <S.ContextMenuItem
      key="slide"
      onClick={() => {
        cycleStripItemType(selectedStripItemId!, true);
        hideContextMenu();
      }}
    >
      Slide
    </S.ContextMenuItem>
  );

  const editLabelMenuItem = (
    <S.ContextMenuItem
      key="edit-label"
      onClick={() => {
        dispatch(setWasFocusedFromKeyboard(false));
        focusStripItemField(selectedStripItemId!, 0);
        dispatch(hideContextMenuInState());
      }}
    >
      Edit label
    </S.ContextMenuItem>
  );

  const changeSeparatorStyleMenuItem = (
    <S.ContextMenuItem
      key="change-style"
      onClick={() => {
        isCreatingStripItem.current = false;
        dispatch(setWasFocusedFromKeyboard(false));
        dispatch(setContextMenuSpec({ ...contextMenuSpec, state: ContextMenuState.SeparatorType }));
      }}
    >
      Change style...
    </S.ContextMenuItem>
  );

  const addSeparatorMenuItem = (
    <S.ContextMenuItem
      key="add-separator"
      onClick={() => {
        isCreatingStripItem.current = true;
        dispatch(setWasFocusedFromKeyboard(false));
        dispatch(setContextMenuSpec({ ...contextMenuSpec, state: ContextMenuState.SeparatorType }));
      }}
    >
      Add separator
    </S.ContextMenuItem>
  );

  const getSeparatorTypeMenuItem = (separatorType: StripItemType) => {
    return (
      <S.ContextMenuItem
        key={separatorType}
        onClick={() => {
          if (isCreatingStripItem.current) {
            const stripItem = new StripItemDto(separatorType);
            const position = selectedStripItemId
              ? getStripItemPosition(selectedStripItemId)!
              : getFirstPositionOnRack(activeBayId, contextMenuSpec.selectedRack!);
            createStripItem(stripItem, position);
            dispatch(hideContextMenuInState());
          } else {
            editStripItemType(selectedStripItemId!, separatorType);
            hideContextMenu();
          }
        }}
      >
        {separatorTypeToString(separatorType)}
      </S.ContextMenuItem>
    );
  };

  const pushMenuItem = (
    <S.ContextMenuItem key="push" onClick={() => dispatch(setContextMenuSpec({ ...contextMenuSpec, state: ContextMenuState.Push }))}>
      Push to...
    </S.ContextMenuItem>
  );

  const getPushMenuItems = () => {
    const menuItems: ReactNode[] = [];
    configuration.stripBays
      .filter((b) => b.id !== activeBayId)
      .forEach((b) =>
        menuItems.push(
          <S.ContextMenuItem
            key={b.id}
            onClick={() => {
              pushStripItemToBay(selectedStripItemId!, b.id);
              hideContextMenu();
            }}
          >
            {b.name}
          </S.ContextMenuItem>,
        ),
      );
    configuration.externalBays.forEach((b) =>
      menuItems.push(
        <S.ContextMenuItem
          key={b.bayId}
          onClick={() => {
            pushStripItemToBay(selectedStripItemId!, b.bayId);
            hideContextMenu();
          }}
        >
          {b.facilityId} - {externalBays.find((eb) => eb.id === b.bayId)?.name}
        </S.ContextMenuItem>,
      ),
    );
    return menuItems;
  };

  const getMenuItems = () => {
    const menuItems = [];
    switch (contextMenuSpec.state) {
      case ContextMenuState.Rack:
        menuItems.push(addSeparatorMenuItem);
        menuItems.push(addHalfStripMenuItem);
        break;
      case ContextMenuState.FlightStrip:
        menuItems.push(pushMenuItem);
        menuItems.push(offsetMenuItem);
        menuItems.push(deleteMenuItem);
        menuItems.push(addSeparatorMenuItem);
        menuItems.push(addHalfStripMenuItem);
        break;
      case ContextMenuState.LockableSeparator:
      case ContextMenuState.Separator:
        if (!(contextMenuSpec.state === ContextMenuState.LockableSeparator && configuration.lockSeparators)) {
          menuItems.push(editLabelMenuItem);
          menuItems.push(changeSeparatorStyleMenuItem);
          menuItems.push(deleteMenuItem);
        }
        menuItems.push(addSeparatorMenuItem);
        menuItems.push(addHalfStripMenuItem);
        break;
      case ContextMenuState.HalfStrip:
        menuItems.push(slideMenuItem);
        menuItems.push(pushMenuItem);
        menuItems.push(offsetMenuItem);
        menuItems.push(deleteMenuItem);
        menuItems.push(addSeparatorMenuItem);
        menuItems.push(addHalfStripMenuItem);
        break;
      case ContextMenuState.SeparatorType:
        menuItems.push(getSeparatorTypeMenuItem(StripItemType.HandwrittenSeparator));
        if (!configuration.lockSeparators) {
          menuItems.push(getSeparatorTypeMenuItem(StripItemType.GreenSeparator));
          menuItems.push(getSeparatorTypeMenuItem(StripItemType.RedSeparator));
          menuItems.push(getSeparatorTypeMenuItem(StripItemType.WhiteSeparator));
        }
        break;
      case ContextMenuState.Push:
        return getPushMenuItems();
      case ContextMenuState.Hidden:
      default:
        break;
    }
    return menuItems;
  };

  if (isReadOnly) {
    return undefined;
  }

  return (
    <S.ContextMenu ref={contextMenuRef} id="context-menu">
      {getMenuItems()}
    </S.ContextMenu>
  );
}

export default ContextMenu;
