import styled from "styled-components";
import { COLOR_MENU_BACKGROUND, COLOR_SECONDARY, COLOR_SECONDARY_MENU_TEXT } from "./colors";

export const Screen = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #00000050;
  position: fixed;
  top: 0;
  z-index: 1000;
`;

const Menu = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100vh - 60px);
  z-index: 10001;
  background-color: ${COLOR_MENU_BACKGROUND};
  box-shadow: -5px 0 40px 5px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  overflow-x: hidden;
`;

export const MenuContent = styled.div`
  margin: 60px 0 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
`;

export const FacilityMenu = styled(Menu)`
  width: 400px;
`;

export const PrinterMenu = styled(Menu)`
  width: 700px;
`;

export const ContextMenu = styled.div`
  position: fixed;
  z-index: 500;
  min-width: 175px;
  background-color: #1d1e22;
  border-radius: 10px;
  overflow: hidden;
`;

export const ContextMenuItem = styled.div`
  height: 25px;
  color: white;
  padding: 5px 15px;
  border-bottom: 1px solid #141414;
  cursor: pointer;
  &:hover {
    background-color: #ffffff12;
  }
`;

export const CloseButton = styled.button`
  border: none;
  background: none !important;
  color: white;
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 1.5em;

  &:hover {
    cursor: pointer;
    color: lightgrey;
  }

  &:active {
    color: grey;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  margin-bottom: 20px;
`;

export const Subtitle = styled.span`
  font-size: "1.2em";
`;

export const Description = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const Version = styled.span`
  margin-top: 5px;
  color: ${COLOR_SECONDARY_MENU_TEXT};
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const LeftTriangle = styled(Triangle)`
  border-right: 25px solid ${COLOR_SECONDARY};
  right: calc(100% + 15px);

  &:hover {
    border-right-color: #5a6268;
  }

  &:active {
    border-right-color: #545b62;
  }
`;

export const RightTriangle = styled(Triangle)`
  border-left: 25px solid ${COLOR_SECONDARY};
  left: calc(100% + 15px);

  &:hover {
    border-left-color: #5a6268;
  }

  &:active {
    border-left-color: #545b62;
  }
`;

export const StripItemContainer = styled.div`
  position: relative;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const PrinterControlsWrapper = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 15px;
`;

export const RackNumberInput = styled.input`
  margin-top: 10px;
  height: 38px;
  color: white;
  background-color: inherit;
  border: 1px solid ${COLOR_SECONDARY};
  border-radius: 4px;
  padding: 0 6px;
  text-align: center;
  width: 75%;
  font-size: 1.2em;
`;
