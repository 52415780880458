import React from "react";
import { useNavigate } from "react-router-dom";
import { useHub } from "src/hooks";
import { logout, useAppDispatch } from "src/redux";
import * as S from "src/styles/ui";

function LogoutButton() {
  const hub = useHub();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <S.Button
      className="primary"
      onClick={() => {
        hub?.stop();
        dispatch(logout());
        navigate("/");
      }}
    >
      Logout
    </S.Button>
  );
}

export default LogoutButton;
