import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Color from "src/styles/colors";
import styled, { css } from "styled-components";

export const LoginBackground = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-image: url(/img/login-background.png);
  background-size: cover;
  background-repeat: no-repeat;
`;

export const LoginSidebar = styled.div`
  height: 100vh;
  width: 35vw;
  min-width: 300px;
  position: fixed;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${Color.COLOR_MENU_BACKGROUND};
  color: white;
  box-shadow: -5px 0 40px 5px rgba(0, 0, 0, 0.5);
`;

export const LoginPanel = styled.div`
  width: 300px;
  background-color: ${Color.COLOR_SECONDARY};
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  box-shadow: -5px 0 40px 5px rgba(0, 0, 0, 0.5);
  position: relative;
`;

export const CurrentLogin = styled.span`
  font-size: 1.2em;
  text-align: center;
`;

export const ServerIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

export const Footer = styled.footer`
  width: 100%;
  background-color: ${Color.COLOR_SECONDARY_MENU_BACKGROUND};
  color: ${Color.COLOR_SECONDARY_MENU_TEXT};
  bottom: 0;
  box-sizing: border-box;
  text-align: right;
  position: absolute;
  padding: 16px;
`;

export const FacilitiesTable = styled.div`
  width: 75%;
  background-color: ${Color.COLOR_SECONDARY_MENU_BACKGROUND};
  margin: 20px;
  overflow-y: auto;
  max-height: 250px;

  > table {
    width: 100%;
  }
`;

export const Row = styled.tr<{ $selected: boolean }>`
  &:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:not(:first-child) {
    td {
      border-top: 1px solid ${Color.COLOR_SECONDARY};
    }
  }

  ${(p) =>
    !p.$selected &&
    css`
      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
        cursor: pointer;
      }

      svg {
        display: none;
      }
    `}

  td {
    padding: 5px;
    text-align: center;
    position: relative;
  }
`;

export const SelectedIndicator = styled(FontAwesomeIcon)`
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
`;
