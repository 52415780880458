import { FLIGHT_STRIP_ANNOTATION_SYMBOLS_MAP, NUMPAD_MAP } from "@vatsim-vnas/js-libs/constants";
import { Response } from "@vatsim-vnas/js-libs/models/api";
import { toast } from "react-toastify";
import { DEBUG_MODE } from "./constants";

export function debugLog<T, S>(message?: T, ...optionalParams: S[]) {
  if (DEBUG_MODE) {
    // eslint-disable-next-line no-console
    console.log(message, ...optionalParams);
  }
}

export function getFieldNumber(e: KeyboardEvent) {
  if (e.code.includes("Numpad")) {
    return NUMPAD_MAP.get(e.key)!;
  }
  return parseInt(e.key, 10) - 1;
}

export function replaceAnnotationSymbols(rawAnnotation: string) {
  FLIGHT_STRIP_ANNOTATION_SYMBOLS_MAP.forEach((v, k) => {
    rawAnnotation = rawAnnotation.replaceAll(k, v);
  });
  return rawAnnotation;
}

export function processResponse<T>(res: Response<T>, errorMessage: string): T | undefined {
  if (res.ok) {
    return res.data!;
  }
  toast.error(`${errorMessage}: ${res.statusText}`);
  return undefined;
}
