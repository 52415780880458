import React from "react";
import { RedirectIosSidebar } from "src/components/login";
import * as S from "src/styles/login";

function RedirectIos() {
  return (
    <>
      <S.LoginBackground />
      <RedirectIosSidebar />
    </>
  );
}

export default RedirectIos;
