import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getFlightStripsFacilitiesAsync } from "@vatsim-vnas/js-libs/api/data";
import { getVnasConfigurationAsync, loginAsync, refreshTokenAsync } from "@vatsim-vnas/js-libs/api/vnas";
import { Environment } from "@vatsim-vnas/js-libs/models/vnas";
import { PositionRole } from "@vatsim-vnas/js-libs/models/vnas/common";
import { SessionInfoDto } from "@vatsim-vnas/js-libs/models/vnas/messaging";
import { tokenHasExpired } from "@vatsim-vnas/js-libs/utils";
import { instanceToInstance } from "class-transformer";
import * as jose from "jose";
import { toast } from "react-toastify";
import { VATSIM_CLIENT_ID, processResponse } from "src/utils";
import { RootState } from "../store";

const getLocalVatsimToken = () => {
  const vatsimToken = localStorage.getItem("vatsim-token");
  if (!vatsimToken) {
    return undefined;
  }

  try {
    const decodedToken = jose.decodeJwt(vatsimToken);
    if (!tokenHasExpired(decodedToken)) {
      return vatsimToken;
    }
  } catch {
    return undefined;
  }

  return undefined;
};

interface LoginProps {
  code: string;
  redirectUrl: string;
}

export const getVnasConfiguration = createAsyncThunk("auth/getVnasConfiguration", async () => {
  const res = await getVnasConfigurationAsync();
  return processResponse(res, `Failed to get vNAS configuration: ${res.statusText}`);
});

export const login = createAsyncThunk("auth/login", async (data: LoginProps, thunkApi) => {
  const { apiBaseUrl } = (thunkApi.getState() as RootState).auth.environment!;
  const res = await loginAsync(apiBaseUrl, data.code, data.redirectUrl, VATSIM_CLIENT_ID);
  return processResponse(res, `Failed to log in: ${res.statusText}`);
});

export const refreshNasToken = createAsyncThunk("auth/refreshNasToken", async (_, thunkApi) => {
  const { environment, vatsimToken } = (thunkApi.getState() as RootState).auth;
  const res = await refreshTokenAsync(environment!.apiBaseUrl, vatsimToken!);
  return processResponse(res, `Failed to log in: ${res.statusText}`);
});

export const getFlightStripsFacilities = createAsyncThunk("auth/getFlightStripsFacilities", async (_, thunkApi) => {
  const { session } = (thunkApi.getState() as RootState).auth;
  if (!session) {
    return [];
  }
  const res = await getFlightStripsFacilitiesAsync(session.artccId, session.getPrimaryFacility());
  return processResponse(res, `Failed to get flight strips facilities: ${res.statusText}`);
});

interface AuthState {
  vatsimCode?: string;
  vatsimToken?: string;
  availableEnvironments: Environment[];
  environment?: Environment;
  nasToken?: string;
  session?: SessionInfoDto;
  flightStripsFacilities: string[];
  signalrIsConnected: boolean;
  fsdIsConnected: boolean;
}

const initialState: AuthState = {
  vatsimToken: getLocalVatsimToken(),
  availableEnvironments: [],
  flightStripsFacilities: [],
  signalrIsConnected: false,
  fsdIsConnected: true,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getVnasConfiguration.fulfilled, (state, action) => {
      if (action.payload) {
        const availableEnvironments = action.payload.environments.filter((e) => !e.isDisabled);
        state.availableEnvironments = availableEnvironments;
        const localEnvironment = localStorage.getItem("environment");
        if (localEnvironment) {
          const availableEnvironment = availableEnvironments.find((e) => e.name === localEnvironment);
          if (availableEnvironment) {
            state.environment = availableEnvironment;
          } else {
            localStorage.removeItem("environment");
            state.environment = availableEnvironments[0]!;
          }
        } else {
          state.environment = availableEnvironments[0]!;
        }
      }
    });
    builder.addCase(getVnasConfiguration.rejected, (_, action) => {
      toast.error(`Failed to get vNAS configuration: ${action.error.message}`);
    });
    builder.addCase(login.fulfilled, (state, action) => {
      if (action.payload) {
        const { nasToken, vatsimToken } = action.payload;
        state.nasToken = nasToken;
        state.vatsimToken = vatsimToken;
        localStorage.setItem("vatsim-token", vatsimToken);
      } else {
        state.vatsimToken = undefined;
        state.vatsimCode = undefined;
        localStorage.removeItem("vatsim-token");
      }
    });
    builder.addCase(login.rejected, (state, action) => {
      state.vatsimToken = undefined;
      state.vatsimCode = undefined;
      localStorage.removeItem("vatsim-token");
      toast.error(`Failed to log in: ${action.error.message}`);
    });
    builder.addCase(refreshNasToken.fulfilled, (state, action) => {
      if (action.payload) {
        state.nasToken = action.payload;
      } else {
        state.vatsimToken = undefined;
        state.vatsimCode = undefined;
        localStorage.removeItem("vatsim-token");
      }
    });
    builder.addCase(refreshNasToken.rejected, (state, action) => {
      state.vatsimToken = undefined;
      state.vatsimCode = undefined;
      localStorage.removeItem("vatsim-token");
      toast.error(`Failed to log in: ${action.error.message}`);
    });
    builder.addCase(getFlightStripsFacilities.fulfilled, (state, action) => {
      if (action.payload) {
        state.flightStripsFacilities = [...action.payload].sort();
      }
    });
    builder.addCase(getFlightStripsFacilities.rejected, (_, action) => {
      toast.error(`Failed to get flight strips facilities: ${action.error.message}`);
    });
  },
  reducers: {
    setVatsimCode(state, action: { payload: string }) {
      state.vatsimCode = action.payload;
    },
    setEnvironment(state, action: { payload: string }) {
      state.environment = state.availableEnvironments.find((e) => e.name === action.payload)!;
      localStorage.setItem("environment", action.payload);
    },
    setSession(state, action: { payload: SessionInfoDto | undefined }) {
      state.session = action.payload;
    },
    setSessionActive(state, action: { payload: boolean }) {
      const newSession = instanceToInstance(state.session!);
      newSession.isActive = action.payload;
      state.session = newSession;
    },
    setSignalrIsConnected(state, action: { payload: boolean }) {
      state.signalrIsConnected = action.payload;
      if (!action.payload) {
        state.session = undefined;
      }
    },
    setFsdIsConnected(state, action: { payload: boolean }) {
      state.fsdIsConnected = action.payload;
    },
    logout(state) {
      state.signalrIsConnected = false;
      state.session = undefined;
      state.nasToken = undefined;
      state.vatsimToken = undefined;
      state.vatsimCode = undefined;
      localStorage.removeItem("vatsim-token");
    },
  },
});

export const { setVatsimCode, setEnvironment, setSession, setSessionActive, setSignalrIsConnected, setFsdIsConnected, logout } = authSlice.actions;

export const vatsimCodeSelector = (state: RootState) => state.auth.vatsimCode;
export const vatsimTokenSelector = (state: RootState) => state.auth.vatsimToken;
export const userIdSelector = (state: RootState) => jose.decodeJwt(state.auth.vatsimToken!).sub!;
export const environmentsSelector = (state: RootState) => state.auth.availableEnvironments;
export const environmentSelector = (state: RootState) => state.auth.environment;
export const nasTokenSelector = (state: RootState) => state.auth.nasToken;
export const sessionSelector = (state: RootState) => state.auth.session;
export const flightStripsFacilitiesSelector = (state: RootState) => state.auth.flightStripsFacilities;
export const signalrIsConnectedSelector = (state: RootState) => state.auth.signalrIsConnected;
export const isReadOnlySelector = (state: RootState) =>
  !state.auth.fsdIsConnected || !state.auth.session?.isActive || state.auth.session.role === PositionRole.Observer;

export default authSlice.reducer;
