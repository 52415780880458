import React from "react";
import { signalrIsConnectedSelector, useAppSelector } from "src/redux";
import * as S from "src/styles/login";
import { VersionNumber } from "src/styles/ui";
import { VERSION } from "src/utils";
import FacilitySelect from "./FacilitySelect";
import VatsimLogin from "./VatsimLogin";

function LoginSidebar() {
  const signalrIsConnected = useAppSelector(signalrIsConnectedSelector);

  return (
    <S.LoginSidebar>
      <S.LoginPanel>{signalrIsConnected ? <FacilitySelect /> : <VatsimLogin />}</S.LoginPanel>
      <S.Footer>
        <b>Version</b>
        <VersionNumber>{VERSION}</VersionNumber>
      </S.Footer>
    </S.LoginSidebar>
  );
}

export default LoginSidebar;
