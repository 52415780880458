import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { sessionSelector, useAppSelector } from "src/redux";

function PrivateRoute() {
  const location = useLocation();
  const session = useAppSelector(sessionSelector);

  if (!session && location.pathname !== "/") {
    localStorage.setItem("login-next", location.pathname);
  }

  if (session) {
    localStorage.removeItem("login-next");
  }

  return session ? <Outlet /> : <Navigate to="/login" replace />;
}

export default PrivateRoute;
