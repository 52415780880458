import { createSlice } from "@reduxjs/toolkit";
import { ContextMenuState, Sound } from "src/enums";
import { ContextMenuSpec, StripItemPosition } from "src/models";
import { RootState } from "../store";

const DEFAULT_RACK_LOCAL_STORAGE_KEY = "default-rack";

const getSavedSoundOption = (sound: Sound) => {
  const savedSoundOption = localStorage.getItem(sound);
  return savedSoundOption ? savedSoundOption === "true" : true;
};

const getSavedDefaultRack = () => {
  const savedDefaultRack = localStorage.getItem(DEFAULT_RACK_LOCAL_STORAGE_KEY);
  const intValue = parseInt(savedDefaultRack ?? "", 10);
  if (Number.isNaN(intValue)) {
    return undefined;
  }
  return intValue;
};

const getSavedEnabledSounds = () => {
  return Object.values(Sound).filter((sound) => getSavedSoundOption(sound));
};

interface SetSoundOptionProps {
  sound: Sound;
  enabled: boolean;
}

interface UiState {
  activeBayId?: string;
  selectedStripItemId?: string;
  isEditingStripItem: boolean;
  wasFocusedFromKeyboard: boolean;
  animatingStripItemIds: string[];
  animationsDisabled: boolean;
  displacingPosition?: StripItemPosition;
  hoveringOverBayButtonId?: string;
  hoveringOverTrash: boolean;
  facilityMenuIsActive: boolean;
  printerMenuIsActive: boolean;
  contextMenuSpec: ContextMenuSpec;
  enabledSounds: Sound[];
  isPlayingSound: boolean;
  defaultRack?: number;
}

const initialState: UiState = {
  isEditingStripItem: false,
  wasFocusedFromKeyboard: false,
  animatingStripItemIds: [],
  hoveringOverTrash: false,
  animationsDisabled: false,
  facilityMenuIsActive: false,
  printerMenuIsActive: false,
  contextMenuSpec: { state: ContextMenuState.Hidden },
  enabledSounds: getSavedEnabledSounds(),
  isPlayingSound: false,
  defaultRack: getSavedDefaultRack(),
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setActiveBay(state, action: { payload: string }) {
      state.activeBayId = action.payload;
    },
    setSelectedStripItem(state, action: { payload: string | undefined }) {
      state.selectedStripItemId = action.payload;
    },
    setIsEditingStripItem(state, action: { payload: boolean }) {
      state.isEditingStripItem = action.payload;
    },
    setWasFocusedFromKeyboard(state, action: { payload: boolean }) {
      state.wasFocusedFromKeyboard = action.payload;
    },
    addAnimatingStripItem(state, action: { payload: string }) {
      state.animatingStripItemIds.push(action.payload);
    },
    removeAnimatingStripItem(state, action: { payload: string }) {
      state.animatingStripItemIds = state.animatingStripItemIds.filter((i) => i !== action.payload);
    },
    setAnimationsDisabled(state, action: { payload: boolean }) {
      state.animationsDisabled = action.payload;
    },
    setDisplacingPosition(state, action: { payload: StripItemPosition | undefined }) {
      state.displacingPosition = action.payload;
    },
    setHoveringOverBayButton(state, action: { payload: string | undefined }) {
      state.hoveringOverBayButtonId = action.payload;
    },
    setHoveringOverTrash(state, action: { payload: boolean }) {
      state.hoveringOverTrash = action.payload;
    },
    setFacilityMenuIsActive(state, action: { payload: boolean }) {
      state.facilityMenuIsActive = action.payload;
    },
    setPrinterMenuIsActive(state, action: { payload: boolean }) {
      state.printerMenuIsActive = action.payload;
    },
    setContextMenuSpec(state, action: { payload: ContextMenuSpec }) {
      state.contextMenuSpec = action.payload;
    },
    hideContextMenu(state) {
      state.contextMenuSpec.state = ContextMenuState.Hidden;
    },
    setSoundOption(state, action: { payload: SetSoundOptionProps }) {
      const { sound, enabled } = action.payload;
      if (enabled) {
        state.enabledSounds.push(sound);
      } else {
        state.enabledSounds = state.enabledSounds.filter((s) => s !== sound);
      }
      localStorage.setItem(sound, enabled.toString());
    },
    setIsPlayingSound(state, action: { payload: boolean }) {
      state.isPlayingSound = action.payload;
    },
    setDefaultRack(state, action: { payload: number | undefined }) {
      state.defaultRack = action.payload;
      if (!action.payload) {
        localStorage.removeItem(DEFAULT_RACK_LOCAL_STORAGE_KEY);
      } else {
        localStorage.setItem(DEFAULT_RACK_LOCAL_STORAGE_KEY, action.payload.toString());
      }
    },
    resetUiState(state) {
      state.activeBayId = undefined;
      state.selectedStripItemId = undefined;
      state.isEditingStripItem = false;
      state.wasFocusedFromKeyboard = false;
      state.animationsDisabled = false;
      state.animatingStripItemIds = [];
      state.displacingPosition = undefined;
      state.hoveringOverBayButtonId = undefined;
      state.facilityMenuIsActive = false;
      state.printerMenuIsActive = false;
      state.contextMenuSpec = { state: ContextMenuState.Hidden };
      state.isPlayingSound = false;
    },
  },
});

export const {
  setActiveBay,
  setSelectedStripItem,
  setIsEditingStripItem,
  setWasFocusedFromKeyboard,
  addAnimatingStripItem,
  removeAnimatingStripItem,
  setAnimationsDisabled,
  setDisplacingPosition,
  setHoveringOverBayButton,
  setHoveringOverTrash,
  setFacilityMenuIsActive,
  setPrinterMenuIsActive,
  setContextMenuSpec,
  hideContextMenu,
  setSoundOption,
  setIsPlayingSound,
  setDefaultRack,
  resetUiState,
} = uiSlice.actions;

export const activeBayIdSelector = (state: RootState) => state.ui.activeBayId!;
export const selectedStripItemIdSelector = (state: RootState) => state.ui.selectedStripItemId;
export const isEditingStripItemSelector = (state: RootState) => state.ui.isEditingStripItem;
export const wasFocusedFromKeyboardSelector = (state: RootState) => state.ui.wasFocusedFromKeyboard;
export const animatingStripItemIdsSelector = (state: RootState) => state.ui.animatingStripItemIds;
export const animationsDisabledSelector = (state: RootState) => state.ui.animationsDisabled;
export const displacingPositionSelector = (state: RootState) => state.ui.displacingPosition;
export const hoveringOverBayButtonIdSelector = (state: RootState) => state.ui.hoveringOverBayButtonId;
export const hoveringOverTrashSelector = (state: RootState) => state.ui.hoveringOverTrash;
export const facilityMenuIsActiveSelector = (state: RootState) => state.ui.facilityMenuIsActive;
export const printerMenuIsActiveSelector = (state: RootState) => state.ui.printerMenuIsActive;
export const contextMenuSpecSelector = (state: RootState) => state.ui.contextMenuSpec;
export const enabledSoundsSelector = (state: RootState) => state.ui.enabledSounds;
export const isPlayingSoundSelector = (state: RootState) => state.ui.isPlayingSound;
export const defaultRackSelector = (state: RootState) => state.ui.defaultRack;

export default uiSlice.reducer;
