import StripItemPosition from "./StripItemPosition";

export default class DraggingStripItemSpec {
  stripItemId: string;

  origin: StripItemPosition;

  xOffset: number;

  yOffset: number;

  deletable: boolean;

  constructor(stripItemId: string, origin: StripItemPosition, xOffset: number, yOffset: number, deletable: boolean) {
    this.stripItemId = stripItemId;
    this.origin = origin;
    this.xOffset = xOffset;
    this.yOffset = yOffset;
    this.deletable = deletable;
  }
}
