import React, { useId } from "react";
import * as S from "src/styles/ui";

interface CheckboxProps {
  checked: boolean;
  label: string;
  onChange: (checked: boolean) => void;
}

function Checkbox({ label, checked, onChange }: Readonly<CheckboxProps>) {
  const id = useId();
  return (
    <S.Checkbox>
      <input type="checkbox" id={id} onChange={(e) => onChange(e.target.checked)} checked={checked} />
      <label htmlFor={id}>{label}</label>
    </S.Checkbox>
  );
}

export default Checkbox;
