enum ContextMenuState {
  Hidden = "Hidden",
  Rack = "Rack",
  FlightStrip = "FlightStrip",
  Separator = "Separator",
  LockableSeparator = "LockableSeparator",
  HalfStrip = "HalfStrip",
  SeparatorType = "SeparatorType",
  Push = "Push",
}

export default ContextMenuState;
