import React, { useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { LoginSidebar } from "src/components/login";
import {
  environmentSelector,
  facilityIdSelector,
  flightStripsFacilitiesSelector,
  login,
  nasTokenSelector,
  refreshNasToken,
  sessionSelector,
  setFacility,
  setVatsimCode,
  useAppDispatch,
  useAppSelector,
  vatsimTokenSelector,
} from "src/redux";
import * as S from "src/styles/login";
import { DOMAIN } from "src/utils";

function Login() {
  const vatsimToken = useAppSelector(vatsimTokenSelector);
  const nasToken = useAppSelector(nasTokenSelector);
  const environment = useAppSelector(environmentSelector);
  const session = useAppSelector(sessionSelector);
  const facilities = useAppSelector(flightStripsFacilitiesSelector);
  const facilityId = useAppSelector(facilityIdSelector);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const code = useRef(searchParams.get("code"));
  const loginNext = localStorage.getItem("login-next");

  useEffect(() => {
    if (facilityId) {
      dispatch(setFacility(undefined));
    }
  }, [facilityId]);

  useEffect(() => {
    if (code.current && environment) {
      dispatch(setVatsimCode(code.current));
      dispatch(login({ code: code.current, redirectUrl: encodeURIComponent(`${DOMAIN}/login`) }));
      code.current = null;
    }
  }, [environment]);

  useEffect(() => {
    if (vatsimToken && code) {
      navigate("/login", { replace: true });
    }
  }, [vatsimToken]);

  useEffect(() => {
    if (vatsimToken && !nasToken && environment) {
      dispatch(refreshNasToken());
    }
  }, [vatsimToken, nasToken, environment]);

  useEffect(() => {
    if (session && loginNext) {
      const nextFacilityId = loginNext.slice(1);
      if (facilities.includes(nextFacilityId)) {
        navigate(loginNext);
      } else {
        localStorage.removeItem("login-next");
        toast.error(`Facility ${nextFacilityId} not available`);
      }
    }
  }, [facilities]);

  return (
    <>
      <S.LoginBackground />
      <LoginSidebar />
    </>
  );
}

export default Login;
